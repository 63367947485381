import { handleQueryResolve } from '../utils'

export default function () {
  return this.query(`
        SELECT
            patient.ID as PatientID
        FROM
            Pharmacy.dbo.Pat as patient 
        WHERE
            patient.ID = 1
    `)
    .then(handleQueryResolve)
}
